import React from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import Footer from "./Footer";
import TopBar from "./TopBar";
import FooterDisclaimer from './FooterDisclaimer';

const useStyles = makeStyles((theme) => ({
  LandingPageRoot: {
    // backgroundColor: theme.palette.background.default,
    // background: "radial-gradient(36.63% 54.25% at 68.63% 50%, rgba(1, 184, 196, 0.5) 0%, rgba(0, 0, 0, 0) 100%)",
    backgroundImage: 'url("images/LandingPageBackgroundImage.png")',
    position: "relative",
  },
  InternalPageRoot: {
    position: "relative",
  },
  MainLayout: {
    minHeight: "calc(100vh - 415px)",
    // backgroundColor: theme.palette.background.default,
  },
  shade1: {
    left: "40%",
    right: "auto",
    width: "550px",
    bottom: "auto",
    filter: "blur(100px)",
    height: "550px",
    top:"15%",
    opacity: "0.55",
    zIndex: "-1",
    position: "absolute",
    transform: "rotate(45deg)",
    borderRadius: "1000px",
    backgroundImage: "radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(42 123 135 / 55%) 0%, rgb(39 121 124 / 60%) 95.78%)",
    [theme.breakpoints.down("sm")]: {
      width: "350px",
      height: "350px",
      left: "25%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "180px",
      left: "10%",
      height: "180px"
    },
  }
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();


  console.log("location---", history.location.pathname);
  return (
    <div className={history.location.pathname === "/" ? `${classes.LandingPageRoot}` : `${classes.InternalPageRoot}`}>
      <TopBar />
      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>
      <div className={classes.shade1}></div>
      <div className={classes.MainLayout}>{children}</div>
      <Footer />
      <FooterDisclaimer />
    </div>
  );
};

export default MainLayout;
