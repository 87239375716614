import React from 'react';
import { makeStyles, Button, Grid, Box, Typography, TextField, FormControl } from '@material-ui/core';
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
    dialogbox: {
        position: "relative",
        padding: "30px",
        "& svg": {
            position: "absolute",
            top: "5px",
            right: "5px",
            cursor: "pointer",
            color: "white",
        }
    },
    notchedOutline: {
        borderWidth: "1px",
        borderColor: "#01B8C4 !important"
      }
}));

function ConnectWalletModal(props) {
    const classes = useStyles();
    const { CloseConnectWallet } = props;
    return (
        <>
            <Box className={classes.dialogbox}>
                <ClearIcon onClick={CloseConnectWallet} />
                <Grid container spacing={2} direction={"column"}>
                    <Grid xs={12} align="center">
                        <Box mb={2}>
                            <Typography variant="h6">
                                Request for  Wallet
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs={12} align="center">
                        <Box my={2}>
                            <FormControl fullWidth>
                                <TextField
                                    variant="outlined"
                                    placeholder="0xdE41BD279c6AB6f81bafd87550dCBDCB39e0BeA3"
                                    name="walletAddress"
                                    // value={values.walletAddress}
                                    fullWidth="true"
                                    size="small"
                                    InputProps={{
                                        classes: {
                                          notchedOutline: classes.notchedOutline
                                        }
                                      }}
                                // onBlur={handleBlur}
                                // onChange={handleChange}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid xs={12} align="center">
                        <Box mt={2}>
                            <Button variant="contained" size="large" color="primary">
                                Submit
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default ConnectWalletModal;