import React from 'react';
import { makeStyles, Box, Typography, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "30px 0px",
        "& p": {
            fontSize: "13px"
        }
    }
}));


function FooterDisclaimer() {
    const classes = useStyles();
    return (
        <>
            <Container maxWidth="lg">
                <Box className={classes.root}>
                    <Typography variant='body1'><span style={{ fontWeight: "bold" }}>Disclaimer </span>:  dolor sit amet, consectetur adipiscing elit. Odio enim eget senectus arcu. Maecenas sed et, amet aliquet venenatis malesuada. Habitant erat orci nisl integer non proin sagittis. Sit in ultrices scelerisque aliquam urna. Et nec elementum velit venenatis, sapien sed sit id. Velit in amet praesent ultricies. Nisl et elementum elementum enim nulla amet varius enim lectus. Tincidunt sed nunc elit viverra eros nunc dictum volutpat. Id quam mi rhoncus ut libero. Mi enim purus id in in suspendisse velit platea tincidunt. Tempus amet risus curabitur egestas. Bibendum dictumst sapien facilisis quisque consequat accumsan aliquam pellentesque. Pretium at eleifend egestas risus, morbi sagittis.
                    </Typography>
                </Box>
            </Container>
        </>
    )
}

export default FooterDisclaimer;