export const NetworkContextName = "Smart Chain - Testnet";
export const ACTIVE_NETWORK = 97;
export const NftContractAddress = "0x122E82B31A7C13137d455fC45C1D7484c5846168";
export const NftStakingAddress = "0x06192323Fb5F19280B0FEb66eE4B98F0AAb9e826";

export const RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";

export const NetworkDetails = [
  {
    chainId: "97",
    chainName: "Smart Chain - Testnet",
    nativeCurrency: {
      name: "Smart Chain - Testnet",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://testnet.bscscan.com"],
    blockExplorerUrls: ["https://bscscan.com/"],
  },
];

export const CategoryButtons = [
  {
    name: "BTC",
  },
  {
    name: "ETH",
  },
  {
    name: "USDT",
  },
  {
    name: "Solana",
  },
  {
    name: "BTC",
  },
  {
    name: "ETH",
  },
  {
    name: "USDT",
  },
  {
    name: "Solana",
  },
];
export const RankingButtons = [
  {
    name: "Last 1 Days Exchanges",
  },
  {
    name: "Last 2 Days Exchanges",
  },
  {
    name: "Last 3 Days Exchanges",
  },
  {
    name: "Last 4 Days Exchanges",
  },
  {
    name: "Last 5 Days Exchanges",
  },
  {
    name: "Last 6 Days Exchanges",
  },
  {
    name: "Last 7 Days Exchanges",
  },
  {
    name: "Last 8 Days Exchanges",
  },
  {
    name: "Last 9 Days Exchanges",
  },
];
export const paginationLimit = [
  {
    name: "01",
  },
  {
    name: " 02",
  },
  {
    name: " 03",
  },
  {
    name: " 04",
  },
  {
    name: " 05",
  },
  {
    name: " 06",
  },
  {
    name: " 07",
  },
  {
    name: " 08",
  },
  {
    name: " 09",
  },
];

export const coinName = [
  {
    coinName:"ETH"
  },
  {
    coinName:"BTC"
  },
  {
    coinName:"USDT"
  },
  {
    coinName:"BTC"
  },
  {
    coinName:"ETH"
  },
  {
    coinName:"USDT"
  },
  {
    coinName:"ETH"
  },
  {
    coinName:"USDT"
  },
  {
    coinName:"ETH"
  },
]
