import React, { useState, useEffect, useContext } from "react";
import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  Box,
  Container,
  Dialog,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "./../../component/Logo";
import Scroll from "react-scroll";
import ConnectWalletModal from "src/component/ConnectWalletModal";
import { FiSun } from "react-icons/fi";
import { FaRegMoon } from "react-icons/fa";
import SettingsContext from "src/context/SettingsContext";
const ScrollLink = Scroll.Link;


const useStyles = makeStyles((theme) => ({
  joinButton: {
    [theme.breakpoints.down("md")]: {
      // marginLeft: "-13px",
    },
  },
  labeltext: {
    padding: "0em 0em 0.7em",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "27px",
    color: "#fff",
    display: "block",
    whiteSpace: "nowrap",
    verticalAlign: "baseline",
    borderRadius: "0.25em",
  },
  dialoginputbox: {
    width: "393px",
    height: "35px",
    border: "2px solid #fff",
    paddingLeft: "10px",
    color: "#fff",
    backgroundColor: "#141414",
    borderRadius: "8px",
    // boxShadow: "2px 5px 2px #888888ab",

    "@media (max-width: 900px)": {
      width: "291px",
    },
  },
  menuButton: {
    fontSize: "15px",
    // lineHeight: "35px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#fff",
    // height: "31px",
    padding: "0px 15px",
    margin: "0 5px",
    "&.active": {
      color: "#01B8C4",
    },
    "&[tabIndex]:focus": {
      color: "#01B8C4",
    },

    "&:hover": {
      color: "#01B8C4",
    },
    "@media (max-width: 1400px)": {
      fontSize: "15px",
    },
    "@media (max-width: 1280px)": {
      fontStyle: "normal",
      fontSize: "15px",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "7px 15px !important",
      height: "33px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  },
  toolbar: {
    display: "flex",
    padding: "20px 0",
    justifyContent: "space-between",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "200px",
    marginBottom: "15px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#1a1a1a",
    width: "260px",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "-2px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "100px !important",
    },
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
    minHeight: "60px !important",
  },
  menuLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  inerrMenu: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    whiteSpace: "nowrap",
    paddingLeft: "25px",
    alignItems: "center",
    // justifyContent: "flex-end",
    justifyContent: "flex-start",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      padding: "0",
    },
    "& li": {
      "&.active": {
        background: "red",
      },
    },
  },
  menuul: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    alignItems: "center",
    margin: "0",
    height: "50px",
    justifyContent: "space-between",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      "& .logoDesk": {
        display: "none",
      },
    },

    "& .buttonRound": {
      width: "41px",
      height: "41px",
      borderRadius: "50%",
      color: "#fff",
      marginLeft: "15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      cursor: "pointer",
      border: "2px solid #FFFFFF",
      [theme.breakpoints.down("md")]: {
        marginBottom: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px",
      },
      "&:hover": {
        background: "#fff",
        color: "#000",
        // border: "2px solid #000",
      },
    },
  },
}));


export default function Header({ buttonClick }) {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const CloseConnectWallet = () => {
    setOpen(false);
  };

  const {
    toolbar,
    menuul,
    drawerContainer,
    drawericon,
    menuLeft,
    logoDrawer,
    containerHeight,
    inerrMenu,
    mainHeader,
    menuButton,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;
  const themeSeeting = useContext(SettingsContext);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1280
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const displayDesktop = () => {
    return (
      <Container maxWidth="lg">
        <Toolbar className={toolbar}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            <Grid item xs={12} align="center">
              {" "}
              {menuText}{" "}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img
              className={logoDrawer}
              src="images/logo.svg"
              alt=""
              style={{ width: "63px" }}
            />

            {menuText}
            <div style={{ padding: "16px" }}>{connectBtn}</div>
          </div>
        </Drawer>

        <div>{productLogo}</div>

        <Grid container spacing={0} alignItems="center" justifyContent="center">
          {/* <Grid item xs={10} align="right">
            <Box mb={1}>
              <IconButton
                variant="contained"
                color="primary"
                onClick={() => {
                  changeTheme("LIGHT");
                }}
              >
                <FiSun />
              </IconButton>
              <IconButton
                variant="contained"
                color="primary"
                onClick={() => {
                  changeTheme("DARK");
                }}
              >
                <FaRegMoon />
              </IconButton>
            </Box>
          </Grid> */}
          <Grid item xs={2} align="right">
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#fff", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const productLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const connectBtn = <Box></Box>;

  const menuText = (
    <nav>
      <ul class={menuul}>
        <Box className={menuLeft}>
          <div className="logoDesk">
            <ScrollLink
              className={menuButton}
              smooth={true}
              duration={500}
              to="section1"
            >
              {" "}
              {productLogo}
            </ScrollLink>
          </div>
        </Box>
        <li>
          <li className="activecolor">
            <ul className={inerrMenu}>
              {/* <Box className="themeButton">
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    changeTheme("LIGHT");
                  }}
                >
                  <FiSun />
                </IconButton>
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    changeTheme("DARK");
                  }}
                >
                  <FaRegMoon />
                </IconButton>
              </Box> */}
              <li>
                {" "}
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={500}
                  to="home"
                  tabIndex="1"
                  onClick={() => history.push("/?id=home")}
                >
                  {" "}
                  Home{" "}
                </ScrollLink>{" "}
              </li>
              {/* <li>
                {" "}
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={500}
                  to="plan"
                  tabIndex="2"
                  onClick={() => history.push("/?id=plan")}
                >
                  {" "}
                  Pricing{" "}
                </ScrollLink>{" "}
              </li> */}
              <li>
                {" "}
                <ScrollLink
                  className={menuButton}
                  // smooth={true}
                  // duration={500}
                  // to="trading"
                  // tabIndex="3"
                  onClick={() => history.push("/about-us")}
                >
                  {" "}
                  About{" "}
                </ScrollLink>{" "}
              </li>

              <li style={{ marginLeft: "15px" }}>
                {" "}

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  target="_blank"
                  // href="https://discord.com/"
                  className={classes.joinButton}
                  onClick={handleClickOpen}
                >
                  {" "}
                  Connect Wallet{" "}
                </Button>

              </li>
            </ul>
          </li>
        </li>
      </ul>
    </nav>
  );

  return (
    <>
      <AppBar
        className="headerNav"
        elevation={0}
        style={location.pathname === "/" ? { background: "transparent" } : {
          background: "#151717",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)"
        }}
      >
        <Container maxWidth="fixed" className={containerHeight}>
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
      <Dialog open={open} onClose={CloseConnectWallet} fullWidth maxWidth="sm">
        <ConnectWalletModal CloseConnectWallet={CloseConnectWallet} />
      </Dialog>
    </>
  );
}
