import React, { useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  TextField,
  IconButton,
  Paper,
  List,
  ListItem,
} from "@material-ui/core";
import {} from "react-feather";
import { TiSocialTwitterCircular } from "react-icons/ti";
import { RiFacebookCircleLine } from "react-icons/ri";
import { AiOutlineInstagram } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
// import Scroll from "react-scroll";
import { useHistory, useLocation } from "react-router-dom";

// const li = Scroll.Link;
const useStyles = makeStyles((theme) => ({
  footerSection: {
    overflow: "hidden",
  },
  menuButton: {
    fontSize: "16px",
    color: "rgba(255, 255, 255, 0.6)",
    cursor: "pointer",
    fontWeight: "300",
    display: "block",
    "&.active": {
      color: "#01B8C4",
    },
    "& span:hover": {
      color: "#01B8C4",
    },

    "&[tabIndex]:focus": {
      color: "#01B8C4",
    },
  },

  icons: {
    color: "rgba(255, 255, 255, 0.6)",
    transition: "0.5s ease-in-out",
    "& img:hover": {
      backgroundColor: "#01B8C4",
      borderRadius: "100px",
    },
  },

  bottomText: {
    paddingTop: "20px",
    [theme.breakpoints.only("xs")]: {
      justifyContent: "start",
    },
  },
  subscribetext: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    padding: "0px 0px 20px 0px",
    alignItem:"center"
  },
  SignUpFooterBtn: {
    backgroundColor: "#152433",
    border: "1px solid #152433",
    borderRadius: "10px",
    width: "183px",
    height: "51px",
  },
  TrandingFooter: {
    backgroundColor: "#01B8C4",
    height: "100%",
    padding: "20px",
    fontSize: "16px",
  },

  aboutsbox: {
    "@media(max-width:599px)": {
      paddingTop: "20px",
    },
  },
  leftGridForLanding: {
    backgroundColor: "#0F1924",
    padding: "19px 0px 0px 38px",
  },
  leftGrid: {
    backgroundColor: "#151717",
    padding: "19px 0px 0px 38px",
  },
  gridLeft: {
    order: "0",
    [theme.breakpoints.down("md")]: {
      order: "1",
    },
  },
  gridRight: {
    order: "1",
    [theme.breakpoints.down("md")]: {
      order: "0",
    },
  },
}));

export default function Liquidity() {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  return (
    <>
      <Box className={classes.footerSection}>
        <Grid container style={{ alignItem: "center" }}>
          <Grid
            item
            xs={12}
            lg={6}
            md={12}
            sm={12}
            className={classes.gridLeft}
          >
            <Box
              className={
                location.pathname === "/"
                  ? `${classes.leftGridForLanding}`
                  : `${classes.leftGrid}`
              }
            >
              <Box className={classes.subscribetext}>
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={6}  align="left">
                  <Box>
                    <img
                      src="./images/logo.png"
                      alt="Logo"
                      width="100%"
                      style={{ maxWidth: "180px" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} align="left" >
                  <Box className={classes.icons}>
                    <IconButton
                      target="_blank"
                      href="https://web.whatsapp.com/"
                    >
                      <img src="/images/whatsApp.svg" />
                    </IconButton>
                    <IconButton target="_blank" href="https://www.twitter.com/">
                      <img src="/images/Twitter.svg" />
                    </IconButton>

                    <IconButton
                      target="_blank"
                      href="https://www.facebook.com/"
                    >
                      <img src="/images/facebook.svg" />
                    </IconButton>

                    <IconButton
                      target="_blank"
                      href="https://www.instagram.com/"
                    >
                      <img src="/images/instagram.svg" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
              </Box>
              <Grid container spacinig={3}>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Box style={{ paddingBottom: "20px" }}>
                    <Typography variant="h6">Features</Typography>

                    <List
                      className={classes.menuButton}
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/cross-exchange-more")}
                    >
                      <ListItem>
                        <span>Automatic Trading</span>
                      </ListItem>
                    </List>

                    <List
                      className={classes.menuButton}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history.push({
                          pathname: "/exchange",
                        });
                      }}
                    >
                      <ListItem>
                        <span>Exchange Arbitrage</span>
                      </ListItem>
                    </List>

                    <List
                      className={classes.menuButton}
                      style={{ cursor: "pointer" }}
                    >
                      <ListItem>
                        <span>Market Making Bot</span>
                      </ListItem>
                    </List>

                    <List
                      className={classes.menuButton}
                      style={{ cursor: "pointer" }}
                    >
                      <ListItem>
                        <span>Social Trading</span>{" "}
                      </ListItem>
                    </List>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Typography variant="h6">Resources</Typography>

                  <List
                    className={classes.menuButton}
                    style={{ cursor: "pointer" }}
                  >
                    <ListItem>
                      {" "}
                      <span>Get Started</span>{" "}
                    </ListItem>
                  </List>

                  <List
                    className={classes.menuButton}
                    style={{ cursor: "pointer" }}
                  >
                    <ListItem>
                      <span>Tutorials</span>{" "}
                    </ListItem>
                  </List>

                  <List
                    className={classes.menuButton}
                    style={{ cursor: "pointer" }}
                  >
                    <ListItem>
                      <span>Documentation</span>
                    </ListItem>
                  </List>
                  <List
                    className={classes.menuButton}
                    style={{ cursor: "pointer" }}
                  >
                    <ListItem>
                      <span>Academy</span>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Box className={classes.aboutsbox}>
                    <Typography variant="h6">Company</Typography>
                    <List
                      className={classes.menuButton}
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/about-us")}
                    >
                      <ListItem>
                        <span>About Us</span>
                      </ListItem>
                    </List>
                    <List
                      className={classes.menuButton}
                      style={{ cursor: "pointer" }}
                    >
                      <ListItem>
                        <span>Careers</span>{" "}
                      </ListItem>
                    </List>
                    <List
                      className={classes.menuButton}
                      style={{ cursor: "pointer" }}
                    >
                      <ListItem>
                        <span>Press</span>{" "}
                      </ListItem>
                    </List>
                    <List
                      className={classes.menuButton}
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/contact-us")}
                    >
                      <ListItem>
                        <span>Contact</span>{" "}
                      </ListItem>
                    </List>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <Box className={classes.aboutsbox}>
                    <Typography variant="h6">Links</Typography>

                    <List
                      className={classes.menuButton}
                      style={{ cursor: "pointer" }}
                    >
                      <ListItem>
                        <span> Cryptocurrencies</span>
                      </ListItem>
                    </List>

                    <List
                      className={classes.menuButton}
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/exchange")}
                    >
                      <ListItem>
                        <span>Exchanges</span>{" "}
                      </ListItem>
                    </List>

                    <List
                      className={classes.menuButton}
                      style={{ cursor: "pointer" }}
                    >
                      <ListItem>
                        <span>Signals</span>
                      </ListItem>
                    </List>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
            md={12}
            sm={12}
            className={classes.gridRight}
          >
            <Box className={classes.TrandingFooter}>
              <Typography className="Starttrading">
                Start trading with us for free!
              </Typography>
              <Typography className="Starttpara">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit
                dignissim eu mauris faucibus viverra sagittis justo viverra. Sit
                fermentum scelerisque ac sagittis purus turpis. Nulla habitant.
              </Typography>
              {/* <Button className={classes.SignUpFooterBtn}>SignUp</Button> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
